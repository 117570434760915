import React from 'react';
import { 
    ChevronUp, 
    MenuOutline, 
    CloseOutline, 
    SearchOutline, 
    CartOutline, 
    ArrowForwardOutline,
    TimeOutline,
    Star,
    LibraryOutline,
    PeopleOutline,
    CheckmarkDoneOutline,  // Added import
    Play,  // Added import
    CalendarOutline,  // Added import
    ChatbubblesOutline,
    CallOutline  // Added import
  } from 'react-ionicons'; // Import specific icons
import logo from '../assets/images/logo.svg'; // Import images
import '../components/LeadForm.css'
import heroBg from '../assets/images/hero-bg.svg';
import heroBanner1 from '../assets/images/hero-banner-1.jpg';
import heroBanner2 from '../assets/images/hero-banner-2.jpg';
import category1 from '../assets/images/category-1.png';
import category2 from '../assets/images/category-2.png';
import category3 from '../assets/images/category-3.png';
import category4 from '../assets/images/category-4.png';
import aboutBanner from '../assets/images/about-banner.jpg';
import aboutShape1 from '../assets/images/about-shape-1.svg';
import aboutShape2 from '../assets/images/about-shape-2.png';
import aboutShape3 from '../assets/images/about-shape-3.png';
import aboutShape4 from '../assets/images/about-shape-4.svg';
import course1 from '../assets/images/course-1.jpg';
import course2 from '../assets/images/course-2.jpg';
import course3 from '../assets/images/course-3.jpg';
import videoBanner from '../assets/images/video-banner.jpg';
import videoShape1 from '../assets/images/video-shape-1.png';
import videoShape2 from '../assets/images/video-shape-2.png';
import blogBg from '../assets/images/blog-bg.svg';
import blog1 from '../assets/images/blog-1.jpg';
import blog2 from '../assets/images/blog-2.jpg';
import blog3 from '../assets/images/blog-3.jpg';
import blogShape from '../assets/images/blog-shape.png'
import shape1 from '../assets/images/hero-shape-1.svg'
import shape2 from '../assets/images/hero-shape-2.png'
import { LogoFacebook, LogoLinkedin, LogoInstagram, LogoTwitter, LogoYoutube } from 'react-ionicons'; // Import necessary icons
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import LeadForm from '../components/LeadForm';


function Home() {
  return (
    <div id="top">
      {/* Header */}
      <Navbar />

      {/* Main Content */}
      <main>
        <article>
          {/* Hero Section */}
          <section
  className="section hero has-bg-image"
  id="home"
  aria-label="home"
  style={{ backgroundImage: `url(${heroBg})` }}
>
  <div className="container">
    <div className="hero-content">
      <h1 className="h1 section-title">
        Reliable <span className="span">Printer Repair</span> Services at Your Doorstep
      </h1>

      <p className="hero-text">
        Is your printer giving you trouble? Our expert technicians at PrinterCarePros.com are here to help! Fast, affordable, and professional repairs to get your printer back up and running.
      </p>

      {/* <a href="tel:+01234567890" className="btn has-before">
        <span className="span">Call Us Toll-Free Number</span>
        <CallOutline color={'#fff'} height="24px" width="24px" />
      </a> */}
    </div>

    {/* Replaced image section with lead form */}
    <LeadForm />
  </div>
</section>



          {/* Category Section */}
          <section className="section category" aria-label="category">
  <div className="container">
    <p className="section-subtitle">Our Services</p>
    <h2 className="h2 section-title">Professional <span className="span">Printer Repair</span> Services</h2>
    <p className="section-text">From minor fixes to major overhauls, we've got you covered.</p>

    <ul className="grid-list">
      <li>
        <div className="category-card" style={{ '--color': '170, 75%, 41%' }}>
          <div className="card-icon">
            <img src={category1} width="40" height="40" loading="lazy" alt="Inkjet Printer Repair" className="img" />
          </div>
          <h3 className="h3">
            <a href="#" className="card-title">Inkjet Printer Repair</a>
          </h3>
          <p className="card-text">Quick and reliable repairs for all inkjet printer models.</p>
          <span className="card-badge">Fast Service</span>
        </div>
      </li>

      <li>
        <div className="category-card" style={{ '--color': '351, 83%, 61%' }}>
          <div className="card-icon">
            <img src={category2} width="40" height="40" loading="lazy" alt="Laser Printer Maintenance" className="img" />
          </div>
          <h3 className="h3">
            <a href="#" className="card-title">Laser Printer Maintenance</a>
          </h3>
          <p className="card-text">Maintenance for your printers to ensure peak performance.</p>
          <span className="card-badge">Top Quality</span>
        </div>
      </li>

      <li>
        <div className="category-card" style={{ '--color': '229, 75%, 58%' }}>
          <div className="card-icon">
            <img src={category3} width="40" height="40" loading="lazy" alt="Toner and Cartridge Refill" className="img" />
          </div>
          <h3 className="h3">
            <a href="#" className="card-title">Toner & Cartridge Refill</a>
          </h3>
          <p className="card-text">High-quality toner and ink refills for all printer brands.</p>
          <span className="card-badge">Affordable Rates</span>
        </div>
      </li>

      <li>
        <div className="category-card" style={{ '--color': '42, 94%, 55%' }}>
          <div className="card-icon">
            <img src={category4} width="40" height="40" loading="lazy" alt="Printer Installation" className="img" />
          </div>
          <h3 className="h3">
            <a href="#" className="card-title">Printer Installation</a>
          </h3>
          <p className="card-text">Professional installation and setup of new printers for home and office use.</p>
          <span className="card-badge">Hassle-Free</span>
        </div>
      </li>
    </ul>
  </div>
</section>


          {/* About Section */}
          <section className="section about" id="about" aria-label="about">
  <div className="container">
    <figure className="about-banner">
      <div className="img-holder" style={{ '--width': 520, '--height': 370 }}>
        <img src={aboutBanner} width="520" height="370" loading="lazy" alt="Printer Repair Experts" className="img-cover" />
      </div>
      {/* <img src={aboutShape1} width="360" height="420" loading="lazy" alt="" className="shape about-shape-1" /> */}
      <img src={aboutShape2} width="371" height="220" loading="lazy" alt="" className="shape about-shape-2" />
      <img src={aboutShape3} width="722" height="528" loading="lazy" alt="" className="shape about-shape-3" />
    </figure>

    <div className="about-content">
      <p className="section-subtitle">About Us</p>
      <h2 className="h2 section-title">Over 10 Years of <span className="span">Printer Repair</span> Excellence</h2>
      <p className="section-text">At PrinterCarePros, we provide reliable, professional, and prompt printer repair services to keep your business running smoothly. From troubleshooting to maintenance, our expert technicians are here to assist you.</p>

      <ul className="about-list">
        <li className="about-item">
          <CheckmarkDoneOutline color={'#000'} height="24px" width="24px" />
          <span className="span">Experienced Technicians</span>
        </li>

        <li className="about-item">
          <CheckmarkDoneOutline color={'#000'} height="24px" width="24px" />
          <span className="span">On-Site & Remote Services</span>
        </li>

        <li className="about-item">
          <CheckmarkDoneOutline color={'#000'} height="24px" width="24px" />
          <span className="span">Affordable & Fast Repairs</span>
        </li>
      </ul>
    </div>
  </div>
</section>


          {/* Course Section */}
          <section className="section course" id="courses" aria-label="course">
  <div className="container">
    <p className="section-subtitle">Why Choose Us</p>
    <h2 className="h2 section-title">Reasons to Choose <span className="span">Our Printer Repair Services</span></h2>

    <ul className="grid-list">
      <li>
        <div className="course-card">
          <figure className="card-banner img-holder" style={{ '--width': 370, '--height': 220 }}>
            <img src={course1} width="370" height="220" loading="lazy" alt="Certified Technicians" className="img-cover" />
          </figure>

          <div className="abs-badge">
            <TimeOutline color={'#000'} height="20px" width="20px" />
            <span className="span">Quick Service</span>
          </div>

          <div className="card-content">
            <span className="badge">Expertise</span>
            <h3 className="h3">
              <a href="#" className="card-title">Certified Technicians</a>
            </h3>
            <p className="rating-text">Our team of certified technicians ensures top-quality repair services for all printer brands.</p>
            <ul className="card-meta-list">
              <li className="card-meta-item">
                <LibraryOutline color={'#000'} height="20px" width="20px" />
                <span className="span">Over 10 Years of Experience</span>
              </li>
              <li className="card-meta-item">
                <PeopleOutline color={'#000'} height="20px" width="20px" />
                <span className="span">Thousands of Happy Customers</span>
              </li>
            </ul>
          </div>
        </div>
      </li>

      <li>
        <div className="course-card">
          <figure className="card-banner img-holder" style={{ '--width': 370, '--height': 220 }}>
            <img src={course2} width="370" height="220" loading="lazy" alt="Affordable Prices" className="img-cover" />
          </figure>

          <div className="abs-badge">
            <TimeOutline color={'#000'} height="20px" width="20px" />
            <span className="span">Cost-Effective</span>
          </div>

          <div className="card-content">
            <span className="badge">Affordable</span>
            <h3 className="h3">
              <a href="#" className="card-title">Affordable Prices</a>
            </h3>
            <p className="rating-text">We offer competitive pricing without compromising on the quality of our services.</p>
            <ul className="card-meta-list">
              <li className="card-meta-item">
                <LibraryOutline color={'#000'} height="20px" width="20px" />
                <span className="span">No Hidden Charges</span>
              </li>
              <li className="card-meta-item">
                <PeopleOutline color={'#000'} height="20px" width="20px" />
                <span className="span">Flexible Service Plans</span>
              </li>
            </ul>
          </div>
        </div>
      </li>

      <li>
        <div className="course-card">
          <figure className="card-banner img-holder" style={{ '--width': 370, '--height': 220 }}>
            <img src={course3} width="370" height="220" loading="lazy" alt="Warranty on Repairs" className="img-cover" />
          </figure>

          <div className="abs-badge">
            <Star color={'#000'} height="20px" width="20px" />
            <span className="span">Warranty</span>
          </div>

          <div className="card-content">
            <span className="badge">Trust</span>
            <h3 className="h3">
              <a href="#" className="card-title">Warranty on Repairs</a>
            </h3>
            <p className="rating-text">All our repair services come with a warranty, ensuring peace of mind for our customers.</p>
            <ul className="card-meta-list">
              <li className="card-meta-item">
                <LibraryOutline color={'#000'} height="20px" width="20px" />
                <span className="span">Quality Assurance</span>
              </li>
              <li className="card-meta-item">
                <PeopleOutline color={'#000'} height="20px" width="20px" />
                <span className="span">Dedicated Support</span>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>

    <a href="#" className="btn has-before">
      <span className="span">Learn More About Our Services</span>
      <ArrowForwardOutline color={'#000'} height="24px" width="24px" />
    </a>
  </div>
</section>


          {/* Video Section */}
          <section className="video has-bg-image" aria-label="video" style={{ backgroundImage: `url(${videoBanner})` }}>
            <div className="container">
              <div className="video-card">
                <div className="video-banner img-holder has-after">
                  <img src={videoBanner} width="970" height="550" loading="lazy" alt="video banner" className="img-cover" />
                  <button className="play-btn" aria-label="play video">
                    <Play color={'#000'} height="24px" width="24px" />
                  </button>
                </div>
                <img src={videoShape1} width="1089" height="605" loading="lazy" alt="" className="shape video-shape-1" />
                <img src={videoShape2} width="158" height="174" loading="lazy" alt="" className="shape video-shape-2" />
              </div>
            </div>
          </section>

          {/* State Section */}
          <section className="section stats" aria-label="stats">
  <div className="container">
    <ul className="grid-list">
      <li>
        <div className="stats-card" style={{ '--color': '170, 75%, 41%' }}>
          <h3 className="card-title">10,000+</h3>
          <p className="card-text">Printers Repaired</p>
        </div>
      </li>
      <li>
        <div className="stats-card" style={{ '--color': '351, 83%, 61%' }}>
          <h3 className="card-title">98%</h3>
          <p className="card-text">First-Time Fix Rate</p>
        </div>
      </li>
      <li>
        <div className="stats-card" style={{ '--color': '260, 100%, 67%' }}>
          <h3 className="card-title">24/7</h3>
          <p className="card-text">Support Availability</p>
        </div>
      </li>
      <li>
        <div className="stats-card" style={{ '--color': '42, 94%, 55%' }}>
          <h3 className="card-title">500+</h3>
          <p className="card-text">Certified Technicians</p>
        </div>
      </li>
    </ul>
  </div>
</section>



          {/* Blog Section */}
          <section className="section blog has-bg-image" id="blog" aria-label="blog" style={{ backgroundImage: `url(${blogBg})` }}>
  <div className="container">
    <p className="section-subtitle">Testimonials</p>
    <h2 className="h2 section-title">What Our Clients Say</h2>

    <ul className="grid-list">
      <li>
        <div className="blog-card">
          <figure className="card-banner img-holder has-after" style={{ '--width': 370, '--height': 370 }}>
            <img src={blog1} width="370" height="370" loading="lazy" alt="Client Testimonial" className="img-cover" />
          </figure>
          <div className="card-content">
            <a href="#" className="card-btn" aria-label="read more">
              <ArrowForwardOutline color={'#000'} height="20px" width="20px" />
            </a>
            <a href="#" className="card-subtitle">John Doe</a>
            <h3 className="h3">
              <a href="#" className="card-title">"Amazing printer repair service, highly recommend!"</a>
            </h3>
            <ul className="card-meta-list">
              <li className="card-meta-item">
                <div className="star-rating" style={{ display: 'flex', gap: '5px' }}>
                  <Star color={'#ffd700'} height="20px" width="20px" />
                  <Star color={'#ffd700'} height="20px" width="20px" />
                  <Star color={'#ffd700'} height="20px" width="20px" />
                  <Star color={'#ffd700'} height="20px" width="20px" />
                  <Star color={'#ffd700'} height="20px" width="20px" />
                </div>
                <span className="span">5 Stars</span>
              </li>
            </ul>
            <p className="card-text">The team at PrinterCarePros fixed my printer in no time, and it's running like new!</p>
          </div>
        </div>
      </li>

      <li>
        <div className="blog-card">
          <figure className="card-banner img-holder has-after" style={{ '--width': 370, '--height': 370 }}>
            <img src={blog2} width="370" height="370" loading="lazy" alt="Client Testimonial" className="img-cover" />
          </figure>
          <div className="card-content">
            <a href="#" className="card-btn" aria-label="read more">
              <ArrowForwardOutline color={'#000'} height="20px" width="20px" />
            </a>
            <a href="#" className="card-subtitle">Jane Smith</a>
            <h3 className="h3">
              <a href="#" className="card-title">"Fast, reliable, and affordable!"</a>
            </h3>
            <ul className="card-meta-list">
              <li className="card-meta-item">
                <div className="star-rating" style={{ display: 'flex', gap: '5px' }}>
                  <Star color={'#ffd700'} height="20px" width="20px" />
                  <Star color={'#ffd700'} height="20px" width="20px" />
                  <Star color={'#ffd700'} height="20px" width="20px" />
                  <Star color={'#ffd700'} height="20px" width="20px" />
                  <Star color={'#ffd700'} height="20px" width="20px" />
                </div>
                <span className="span">5 Stars</span>
              </li>
            </ul>
            <p className="card-text">PrinterCarePros exceeded my expectations with their exceptional service.</p>
          </div>
        </div>
      </li>

      <li>
        <div className="blog-card">
          <figure className="card-banner img-holder has-after" style={{ '--width': 370, '--height': 370 }}>
            <img src={blog3} width="370" height="370" loading="lazy" alt="Client Testimonial" className="img-cover" />
          </figure>
          <div className="card-content">
            <a href="#" className="card-btn" aria-label="read more">
              <ArrowForwardOutline color={'#000'} height="20px" width="20px" />
            </a>
            <a href="#" className="card-subtitle">Mark Johnson</a>
            <h3 className="h3">
              <a href="#" className="card-title">"Professional and efficient service!"</a>
            </h3>
            <ul className="card-meta-list">
              <li className="card-meta-item">
                <div className="star-rating" style={{ display: 'flex', gap: '5px' }}>
                  <Star color={'#ffd700'} height="20px" width="20px" />
                  <Star color={'#ffd700'} height="20px" width="20px" />
                  <Star color={'#ffd700'} height="20px" width="20px" />
                  <Star color={'#ffd700'} height="20px" width="20px" />
                  <Star color={'#ffd700'} height="20px" width="20px" />
                </div>
                <span className="span">5 Stars</span>
              </li>
            </ul>
            <p className="card-text">I am extremely satisfied with the service provided by PrinterCarePros.</p>
          </div>
        </div>
      </li>

      {/* Additional testimonials can be added here... */}
    </ul>

    <img src={blogShape} width="186" height="186" loading="lazy" alt="" className="shape blog-shape" />
  </div>
</section>


        </article>
      </main>

      {/* Footer */}
      <Footer />


      {/* Back to top */}
      <a href="#top" className="back-top-btn" aria-label="back to top" data-back-top-btn>
        <ChevronUp color={'#000'} height="24px" width="24px" />
      </a>
    </div>
  );
}

export default Home;
