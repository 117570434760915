import React from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink from react-router-dom
import footerBg from '../assets/images/footer-bg.png';
import logoLight from '../assets/images/logo-light-1.svg';
import { ArrowForwardOutline } from 'react-ionicons';

const Footer = () => {
  return (
    <>
      <footer className="footer" style={{ backgroundImage: `url(${footerBg})` }}>
        <div className="footer-top section">
          <div className="container grid-list">
            <div className="footer-brand">
              <NavLink to="/" className="logo">
                <img src={logoLight} width="162" height="50" alt="PrinterCarePros logo" />
              </NavLink>
              <p className="footer-brand-text">
                Your trusted partner for reliable printer repair services. From simple fixes to complex repairs, we handle it all.
              </p>
              <div className="wrapper">
                <span className="span">Address:</span>
                <address className="address">1234 Maplewood Drive Springfield, IL 62704 United States</address>
              </div>
              <div className="wrapper">
                <span className="span">Call:</span>
                <a href="tel:+01234567890" className="footer-link">+1 (555) 123-4567</a>
              </div>
              <div className="wrapper">
                <span className="span">Email:</span>
                <a href="mailto:support@printercarepros.com" className="footer-link">support@printercarepros.com</a>
              </div>
            </div>

            <ul className="footer-list">
              <li>
                <p className="footer-list-title">Company</p>
              </li>
              <li><NavLink to="/about" className="footer-link">About Us</NavLink></li>
              <li><NavLink to="/contact" className="footer-link">Contact Us</NavLink></li>
            </ul>

            <ul className="footer-list">
              <li>
                <p className="footer-list-title">Quick Links</p>
              </li>
              <li><NavLink to="/terms-and-conditions" className="footer-link">Terms & Conditions</NavLink></li>
              <li><NavLink to="/privacy-policy" className="footer-link">Privacy Policy</NavLink></li>
            </ul>

            <div className="footer-list">
              <p className="footer-list-title">Stay Connected</p>
              <p className="footer-list-text">
                Subscribe to our newsletter for the latest updates on printer care and maintenance tips.
              </p>
              <form action="" className="newsletter-form">
                <input type="email" name="email_address" placeholder="Your email" required className="input-field" />
                <button type="submit" className="btn has-before">
                  <span className="span">Subscribe</span>
                  <ArrowForwardOutline color={'#000'} height="20px" width="20px" />
                </button>
              </form>
              {/* Add social media links here as NavLinks if internal routing is needed */}
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container">
            <p className="copyright">
              © 2024 PrinterCarePros. All Rights Reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
