import React from 'react';
import { useNavigate } from 'react-router-dom'; // Use this if you're using React Router for navigation
import './LeadForm.css'; // Import your form styles

const LeadForm = () => {
  const navigate = useNavigate(); // For programmatic navigation

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission
    // Add any form validation or data processing logic here

    // Redirect to the thank you page
    navigate('/thankyou');
  };

  return (
    <div className="lead-form-container">
      <h2 className="h2 form-title">Request a Callback</h2>
      <form className="lead-form" onSubmit={handleSubmit}>
        <div className="input-group">
          <label htmlFor="name">Name</label>
          <input type="text" id="name" name="name" placeholder="Enter your name" required />
        </div>
        <div className="input-group">
          <label htmlFor="phone">Phone</label>
          <input type="tel" id="phone" name="phone" placeholder="Enter your phone number" required />
        </div>
        <div className="input-group">
          <label htmlFor="email">Email</label>
          <input type="email" id="email" name="email" placeholder="Enter your email" required />
        </div>
        <button type="submit" className="btn has-before">
          <span className="span">Submit</span>
        </button>
      </form>
    </div>
  );
};

export default LeadForm;
