import React from 'react';
import { CallOutline, MailOutline, LocationOutline } from 'react-ionicons';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../pages/Contact.css'
import aboutBanner from '../assets/images/about-banner.jpg';

function Contact() {
  return (
    <div>
      {/* Header */}
      <Navbar />

      {/* Main Content */}
      <main>
        <article>
          {/* Hero Section */}
          <section className="section heros has-bg-image" aria-label="contact-hero" style={{ backgroundImage: `url(${aboutBanner})` }}>
            <div className="container">
              <div className="hero-contents">
                <h1 className="h1 section-title">
                  Get in <span className="span">Touch</span> with Us
                </h1>
                <p className="hero-text">
                  We’re here to assist you with all your printer repair needs. Reach out to us for any inquiries or support!
                </p>
              </div>
            </div>
          </section>

          {/* Contact Information Section */}
          <section className="section contact-infos" aria-label="contact-infos">
            <div className="container">
              <h2 className="h2 section-title">Contact Details</h2>
              <ul className="contact-detailss">
                <li>
                  <LocationOutline color={'#333'} height="24px" width="24px" />
                  <span>1234 PrinterCare Avenue, Springfield, IL 62704</span>
                </li>
                <li>
                  <CallOutline color={'#333'} height="24px" width="24px" />
                  <span>+1 (555) 123-4567</span>
                </li>
                <li>
                  <MailOutline color={'#333'} height="24px" width="24px" />
                  <span>support@printercarepros.com</span>
                </li>
              </ul>
            </div>
          </section>

          {/* Contact Form Section */}
          <section className="section contact-form" aria-label="contact-form">
            <div className="container">
              <h2 className="h2 section-title">Send Us a Message</h2>
              <form className="form">
                <div className="form-group">
                  <label htmlFor="name">Your Name</label>
                  <input type="text" id="name" name="name" placeholder="Enter your name" required />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Your Email</label>
                  <input type="email" id="email" name="email" placeholder="Enter your email" required />
                </div>
                <div className="form-group">
                  <label htmlFor="message">Your Message</label>
                  <textarea id="message" name="message" placeholder="Enter your message" required></textarea>
                </div>
                <button type="submit" className="btns">
                  Send Message
                </button>
              </form>
            </div>
          </section>
        </article>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Contact;
