import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import privacyBanner from '../assets/images/blog-1.jpg'; // Use your privacy banner here.

const PrivacyPolicy = () => {
  return (
    <div>
      {/* Header */}
      <Navbar />

      {/* Main Content */}
      <main>
        <article>
          {/* Hero Section */}
          <section 
            className="section hero has-bg-image" 
            aria-label="privacy-hero" 
            style={{ backgroundImage: `url(${privacyBanner})`, backgroundSize: 'cover', padding: '80px 0', textAlign: 'center' }}
          >
            <div style={{ color: '#fff', maxWidth: '800px', margin: '0 auto' }}>
              <h1 style={{ fontSize: '3rem', fontWeight: '600' }}>Privacy <span style={{ color: '#ffd700' }}>Policy</span></h1>
              <p style={{ fontSize: '1.2rem', marginTop: '20px' }}>
                Our commitment to protecting your privacy and ensuring the safety of your personal information.
              </p>
            </div>
          </section>

          {/* Introduction Section */}
          <section style={{ padding: '40px 15px', maxWidth: '800px', margin: '0 auto', textAlign: 'center' }}>
            <h2 style={{ fontSize: '2rem', fontWeight: '600', color: '#333' }}>Introduction</h2>
            <p style={{ color: '#666', fontSize: '1rem', marginTop: '15px' }}>
              At PrinterCarePros, we are committed to safeguarding the privacy of our users. This Privacy Policy outlines how we collect, use, 
              and protect your personal information in connection with our services. By using our website, you agree to the collection and 
              use of information in accordance with this policy.
            </p>
          </section>

          {/* Data Collection Section */}
          <section style={{ padding: '40px 15px', maxWidth: '800px', margin: '0 auto' }}>
            <h2 style={{ fontSize: '2rem', fontWeight: '600', color: '#333' }}>Data Collection</h2>
            <p style={{ color: '#666', fontSize: '1rem', marginTop: '15px' }}>
              We collect several types of information to provide and improve our services. This includes:
            </p>
            <ul style={{ color: '#666', fontSize: '1rem', marginTop: '15px', paddingLeft: '20px', listStyleType: 'disc' }}>
              <li><strong>Personal Information:</strong> Name, email address, phone number, and mailing address.</li>
              <li><strong>Usage Data:</strong> Information on how our website is accessed and used, such as IP addresses, browser type, and usage patterns.</li>
              <li><strong>Cookies and Tracking:</strong> Cookies, beacons, and similar technologies for tracking user activity and preferences.</li>
            </ul>
          </section>

          {/* Use of Data Section */}
          <section style={{ padding: '40px 15px', maxWidth: '800px', margin: '0 auto' }}>
            <h2 style={{ fontSize: '2rem', fontWeight: '600', color: '#333' }}>Use of Data</h2>
            <p style={{ color: '#666', fontSize: '1rem', marginTop: '15px' }}>
              The data we collect is used for various purposes, including:
            </p>
            <ul style={{ color: '#666', fontSize: '1rem', marginTop: '15px', paddingLeft: '20px', listStyleType: 'disc' }}>
              <li>To provide and maintain our services</li>
              <li>To notify you about changes to our service</li>
              <li>To improve our website and personalize your experience</li>
              <li>To provide customer support</li>
              <li>To monitor the usage of our website</li>
              <li>To detect, prevent, and address technical issues</li>
            </ul>
          </section>

          {/* Cookies Section */}
          <section style={{ padding: '40px 15px', maxWidth: '800px', margin: '0 auto' }}>
            <h2 style={{ fontSize: '2rem', fontWeight: '600', color: '#333' }}>Cookies</h2>
            <p style={{ color: '#666', fontSize: '1rem', marginTop: '15px' }}>
              Cookies are small data files stored on your device to track website activity and improve your experience. You can control and/or delete cookies through your browser settings. 
              However, disabling cookies may affect your experience on our site.
            </p>
          </section>

          {/* Security Section */}
          <section style={{ padding: '40px 15px', maxWidth: '800px', margin: '0 auto' }}>
            <h2 style={{ fontSize: '2rem', fontWeight: '600', color: '#333' }}>Security of Your Data</h2>
            <p style={{ color: '#666', fontSize: '1rem', marginTop: '15px' }}>
              We value your trust in providing us with your personal information, and we strive to use commercially acceptable means to protect it. However, remember that no method of 
              transmission over the internet or method of electronic storage is 100% secure. While we aim to use strong protection methods, we cannot guarantee absolute security.
            </p>
          </section>

          {/* User Rights Section */}
          <section style={{ padding: '40px 15px', maxWidth: '800px', margin: '0 auto' }}>
            <h2 style={{ fontSize: '2rem', fontWeight: '600', color: '#333' }}>Your Rights</h2>
            <p style={{ color: '#666', fontSize: '1rem', marginTop: '15px' }}>
              You have certain rights regarding the personal information we hold about you. Depending on your location, these rights may include:
            </p>
            <ul style={{ color: '#666', fontSize: '1rem', marginTop: '15px', paddingLeft: '20px', listStyleType: 'disc' }}>
              <li>The right to access, update, or delete your personal information.</li>
              <li>The right to withdraw consent where we rely on your consent to process your data.</li>
              <li>The right to lodge a complaint with a data protection authority.</li>
            </ul>
          </section>

          {/* Contact Us Section */}
          <section style={{ padding: '40px 15px', maxWidth: '800px', margin: '0 auto', textAlign: 'center' }}>
            <h2 style={{ fontSize: '2rem', fontWeight: '600', color: '#333' }}>Contact Us</h2>
            <p style={{ color: '#666', fontSize: '1rem', marginTop: '15px' }}>
              If you have any questions about this Privacy Policy, please contact us:
            </p>
            <p style={{ color: '#333', fontSize: '1rem', marginTop: '15px' }}>
              <strong>Email:</strong> support@printercarepros.com
            </p>
            <p style={{ color: '#333', fontSize: '1rem' }}>
              <strong>Phone:</strong> +1 (555) 123-4567
            </p>
            <p style={{ color: '#333', fontSize: '1rem' }}>
              <strong>Address:</strong> 1234 Maplewood Drive, Springfield, IL 62704
            </p>
          </section>
        </article>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
