import React from 'react';
import { 
    CheckmarkDoneOutline,
    PeopleOutline,
    LibraryOutline,
    Star,
    CalendarOutline,
    TimeOutline,
    Play,
    ArrowForwardOutline
} from 'react-ionicons';
import aboutBanner from '../assets/images/about-banner.jpg';
import aboutShape1 from '../assets/images/about-shape-1.svg';
import aboutShape2 from '../assets/images/about-shape-2.png';
import aboutShape3 from '../assets/images/about-shape-3.png';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import course1 from '../assets/images/course-1.jpg';
import course2 from '../assets/images/course-2.jpg';
import course3 from '../assets/images/course-3.jpg';

function AboutUs() {
  return (
    <div>
      {/* Header */}
      <Navbar />

      {/* Main Content */}
      <main>
        <article>
          {/* About Hero Section */}
          <section className="section hero has-bg-image" aria-label="about-hero" style={{ backgroundImage: `url(${aboutBanner})`, color: '#fff' }}>
            <div className="container">
              <div className="hero-content">
                <h1 className="h1 section-title" style={{ color: '#fff' }}>
                  Discover Our <span style={{ color: '#fff' }}>Printer Care Expertise</span>
                </h1>

                <p className="hero-text" style={{ color: '#fff' }}>
                  With over a decade of experience, PrinterCarePros brings unparalleled quality, expertise, and dedication to all your printer service needs.
                </p>
              </div>
            </div>
          </section>

          {/* Our Mission Section */}
          <section className="section about" aria-label="our-mission">
            <div className="container">
              <figure className="about-banner">
                <div className="img-holder" style={{ '--width': 520, '--height': 370 }}>
                  <img src={aboutBanner} width="520" height="370" loading="lazy" alt="Our Mission" className="img-cover" />
                </div>
                <img src={aboutShape1} width="360" height="420" loading="lazy" alt="" className="shape about-shape-1" />
                <img src={aboutShape2} width="371" height="220" loading="lazy" alt="" className="shape about-shape-2" />
                <img src={aboutShape3} width="722" height="528" loading="lazy" alt="" className="shape about-shape-3" />
              </figure>

              <div className="about-content">
                <p className="section-subtitle" style={{ color: '#333', fontWeight: '600' }}>Our Mission</p>
                <h2 className="h2 section-title" style={{ color: '#000', fontSize: '2.5rem' }}>
                  Providing Reliable <span style={{ color: '#00bfa6' }}>Printer Services</span> That You Can Trust
                </h2>
                <p className="section-text" style={{ color: '#666' }}>
                  At PrinterCarePros, we are committed to providing top-quality printer repair services, prioritizing customer satisfaction and ensuring that your printer is always in peak condition.
                </p>
              </div>
            </div>
          </section>

          {/* Why Choose Us Section */}
          <section className="section course" id="courses" aria-label="course">
  <div className="container">
    <p className="section-subtitle">Why Choose Us</p>
    <h2 className="h2 section-title">Reasons to Choose <span className="span">Our Printer Repair Services</span></h2>

    <ul className="grid-list">
      <li>
        <div className="course-card">
          <figure className="card-banner img-holder" style={{ '--width': 370, '--height': 220 }}>
            <img src={course1} width="370" height="220" loading="lazy" alt="Certified Technicians" className="img-cover" />
          </figure>

          <div className="abs-badge">
            <TimeOutline color={'#000'} height="20px" width="20px" />
            <span className="span">Quick Service</span>
          </div>

          <div className="card-content">
            <span className="badge">Expertise</span>
            <h3 className="h3">
              <a href="#" className="card-title">Certified Technicians</a>
            </h3>
            <p className="rating-text">Our team of certified technicians ensures top-quality repair services for all printer brands.</p>
            <ul className="card-meta-list">
              <li className="card-meta-item">
                <LibraryOutline color={'#000'} height="20px" width="20px" />
                <span className="span">Over 10 Years of Experience</span>
              </li>
              <li className="card-meta-item">
                <PeopleOutline color={'#000'} height="20px" width="20px" />
                <span className="span">Thousands of Happy Customers</span>
              </li>
            </ul>
          </div>
        </div>
      </li>

      <li>
        <div className="course-card">
          <figure className="card-banner img-holder" style={{ '--width': 370, '--height': 220 }}>
            <img src={course2} width="370" height="220" loading="lazy" alt="Affordable Prices" className="img-cover" />
          </figure>

          <div className="abs-badge">
            <TimeOutline color={'#000'} height="20px" width="20px" />
            <span className="span">Cost-Effective</span>
          </div>

          <div className="card-content">
            <span className="badge">Affordable</span>
            <h3 className="h3">
              <a href="#" className="card-title">Affordable Prices</a>
            </h3>
            <p className="rating-text">We offer competitive pricing without compromising on the quality of our services.</p>
            <ul className="card-meta-list">
              <li className="card-meta-item">
                <LibraryOutline color={'#000'} height="20px" width="20px" />
                <span className="span">No Hidden Charges</span>
              </li>
              <li className="card-meta-item">
                <PeopleOutline color={'#000'} height="20px" width="20px" />
                <span className="span">Flexible Service Plans</span>
              </li>
            </ul>
          </div>
        </div>
      </li>

      <li>
        <div className="course-card">
          <figure className="card-banner img-holder" style={{ '--width': 370, '--height': 220 }}>
            <img src={course3} width="370" height="220" loading="lazy" alt="Warranty on Repairs" className="img-cover" />
          </figure>

          <div className="abs-badge">
            <Star color={'#000'} height="20px" width="20px" />
            <span className="span">Warranty</span>
          </div>

          <div className="card-content">
            <span className="badge">Trust</span>
            <h3 className="h3">
              <a href="#" className="card-title">Warranty on Repairs</a>
            </h3>
            <p className="rating-text">All our repair services come with a warranty, ensuring peace of mind for our customers.</p>
            <ul className="card-meta-list">
              <li className="card-meta-item">
                <LibraryOutline color={'#000'} height="20px" width="20px" />
                <span className="span">Quality Assurance</span>
              </li>
              <li className="card-meta-item">
                <PeopleOutline color={'#000'} height="20px" width="20px" />
                <span className="span">Dedicated Support</span>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>

    <a href="#" className="btn has-before">
      <span className="span">Learn More About Our Services</span>
      <ArrowForwardOutline color={'#000'} height="24px" width="24px" />
    </a>
  </div>
</section>
        </article>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default AboutUs;
