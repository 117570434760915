import React from 'react';
import { CheckmarkDoneOutline } from 'react-ionicons'; // Importing the relevant icon
import '../components/ThankYouPage.css'; // Ensure you create and style this CSS file

const ThankYouPage = () => {
  return (
    <section className="section thank-you-section">
      <div className="container">
        <div className="thank-you-content">
          <CheckmarkDoneOutline color={'#42a5f5'} height="64px" width="64px" />
          <h1 className="h1 section-title">Thank You!</h1>
          <p className="thank-you-text">
            Your submission has been successfully received. Our team will contact you shortly to discuss your printer repair service.
          </p>
          <a href="/" className="btn has-before">
            <span className="span">Return to Home</span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default ThankYouPage;
