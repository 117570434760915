import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import {
  ChevronUp,
  MenuOutline,
  CloseOutline,
  CallOutline,
} from 'react-ionicons';
import logo from '../assets/images/logo.svg'; // Adjust the path as necessary

function Navbar() {
  const [isNavbarActive, setIsNavbarActive] = useState(false);
  const [isHeaderActive, setIsHeaderActive] = useState(false);
  const [isBackTopBtnActive, setIsBackTopBtnActive] = useState(false);

  const toggleNavbar = () => {
    setIsNavbarActive(!isNavbarActive);
  };

  const closeNavbar = () => {
    setIsNavbarActive(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsHeaderActive(true);
        setIsBackTopBtnActive(true);
      } else {
        setIsHeaderActive(false);
        setIsBackTopBtnActive(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {/* Header */}
      <header className={`header ${isHeaderActive ? 'active' : ''}`} data-header>
        <div className="container">
          {/* Logo */}
          <Link to="/" className="logo" onClick={closeNavbar}>
            <img src={logo} width="162" height="50" alt="EduWeb logo" />
          </Link>

          {/* Navbar */}
          <nav className={`navbar ${isNavbarActive ? 'active' : ''}`} data-navbar>
            <div className="wrapper">
              <Link to="/" className="logo" onClick={closeNavbar}>
                <img src={logo} width="162" height="50" alt="EduWeb logo" />
              </Link>

              <button className="nav-close-btn" aria-label="close menu" onClick={toggleNavbar}>
                <CloseOutline color={'#000'} height="24px" width="24px" />
              </button>
            </div>

            <ul className="navbar-list">
              <li className="navbar-item">
                <Link to="/" className="navbar-link" onClick={closeNavbar}>Home</Link>
              </li>
              <li className="navbar-item">
                <Link to="/about" className="navbar-link" onClick={closeNavbar}>About</Link>
              </li>
              <li className="navbar-item">
                <Link to="/contact" className="navbar-link" onClick={closeNavbar}>Contact Us</Link>
              </li>
            </ul>
          </nav>

          {/* Header Actions */}
          <div className="header-actions">
            {/* <a href="tel:+01234567890" className="btn has-before"> 
              <span className="span">Call Us Toll-Free</span>
              <CallOutline color={'#fff'} height="24px" width="24px" />
            </a> */}

            <button className="header-action-btn" aria-label="open menu" onClick={toggleNavbar}>
              <MenuOutline color={'#000'} height="24px" width="24px" />
            </button>
          </div>

          {/* Overlay */}
          <div className={`overlay ${isNavbarActive ? 'active' : ''}`} onClick={closeNavbar}></div>
        </div>
      </header>

      {/* Back to top button */}
      <button
        className={`back-top-btn ${isBackTopBtnActive ? 'active' : ''}`}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <ChevronUp color={'#000'} height="24px" width="24px" />
      </button>
    </>
  );
}

export default Navbar;
