import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import termsBanner from '../assets/images/blog-1.jpg'; // Use an appropriate banner image for the Terms & Conditions page.

const TermsAndConditions = () => {
  return (
    <div>
      {/* Header */}
      <Navbar />

      {/* Main Content */}
      <main>
        <article>
          {/* Hero Section */}
          <section 
            className="section hero has-bg-image" 
            aria-label="terms-hero" 
            style={{ backgroundImage: `url(${termsBanner})`, backgroundSize: 'cover', padding: '80px 0', textAlign: 'center' }}
          >
            <div style={{ color: '#fff', maxWidth: '800px', margin: '0 auto' }}>
              <h1 style={{ fontSize: '3rem', fontWeight: '600' }}>Terms & <span style={{ color: '#ffd700' }}>Conditions</span></h1>
              <p style={{ fontSize: '1.2rem', marginTop: '20px' }}>
                Please read these terms and conditions carefully before using our services.
              </p>
            </div>
          </section>

          {/* Introduction Section */}
          <section style={{ padding: '40px 15px', maxWidth: '800px', margin: '0 auto', textAlign: 'center' }}>
            <h2 style={{ fontSize: '2rem', fontWeight: '600', color: '#333' }}>Introduction</h2>
            <p style={{ color: '#666', fontSize: '1rem', marginTop: '15px' }}>
              These Terms & Conditions govern the use of PrinterCarePros' website and services. By accessing our website or using our services, 
              you agree to comply with these terms. If you do not agree to these terms, please do not use our website or services.
            </p>
          </section>

          {/* Use of Services Section */}
          <section style={{ padding: '40px 15px', maxWidth: '800px', margin: '0 auto' }}>
            <h2 style={{ fontSize: '2rem', fontWeight: '600', color: '#333' }}>Use of Services</h2>
            <p style={{ color: '#666', fontSize: '1rem', marginTop: '15px' }}>
              Our services are available to individuals who are at least 18 years old. You agree to use our services only for lawful purposes 
              and in compliance with all applicable laws and regulations. You are responsible for the accuracy of the information you provide 
              to us and for maintaining the confidentiality of your account.
            </p>
          </section>

          {/* Intellectual Property Section */}
          <section style={{ padding: '40px 15px', maxWidth: '800px', margin: '0 auto' }}>
            <h2 style={{ fontSize: '2rem', fontWeight: '600', color: '#333' }}>Intellectual Property</h2>
            <p style={{ color: '#666', fontSize: '1rem', marginTop: '15px' }}>
              All content on the PrinterCarePros website, including text, images, graphics, and software, is the intellectual property of 
              PrinterCarePros or its licensors. You may not use, reproduce, or distribute any content from our website without our express 
              written permission.
            </p>
          </section>

          {/* Limitation of Liability Section */}
          <section style={{ padding: '40px 15px', maxWidth: '800px', margin: '0 auto' }}>
            <h2 style={{ fontSize: '2rem', fontWeight: '600', color: '#333' }}>Limitation of Liability</h2>
            <p style={{ color: '#666', fontSize: '1rem', marginTop: '15px' }}>
              PrinterCarePros will not be liable for any damages arising from the use or inability to use our services, including direct, 
              indirect, incidental, or consequential damages. We are not responsible for any errors, omissions, or inaccuracies on our website.
            </p>
          </section>

          {/* Termination Section */}
          <section style={{ padding: '40px 15px', maxWidth: '800px', margin: '0 auto' }}>
            <h2 style={{ fontSize: '2rem', fontWeight: '600', color: '#333' }}>Termination</h2>
            <p style={{ color: '#666', fontSize: '1rem', marginTop: '15px' }}>
              We reserve the right to terminate or suspend your access to our services at any time, without prior notice or liability, for any 
              reason, including if you breach these Terms & Conditions.
            </p>
          </section>

          {/* Governing Law Section */}
          <section style={{ padding: '40px 15px', maxWidth: '800px', margin: '0 auto' }}>
            <h2 style={{ fontSize: '2rem', fontWeight: '600', color: '#333' }}>Governing Law</h2>
            <p style={{ color: '#666', fontSize: '1rem', marginTop: '15px' }}>
              These Terms & Conditions are governed by and construed in accordance with the laws of the United States. Any disputes arising 
              out of or related to these terms will be resolved in the courts of the United States.
            </p>
          </section>

          {/* Changes to Terms Section */}
          <section style={{ padding: '40px 15px', maxWidth: '800px', margin: '0 auto' }}>
            <h2 style={{ fontSize: '2rem', fontWeight: '600', color: '#333' }}>Changes to Terms</h2>
            <p style={{ color: '#666', fontSize: '1rem', marginTop: '15px' }}>
              We may update these Terms & Conditions from time to time. We will notify you of any changes by posting the new terms on our website. 
              You are advised to review these terms periodically for any changes.
            </p>
          </section>

          {/* Contact Us Section */}
          <section style={{ padding: '40px 15px', maxWidth: '800px', margin: '0 auto', textAlign: 'center' }}>
            <h2 style={{ fontSize: '2rem', fontWeight: '600', color: '#333' }}>Contact Us</h2>
            <p style={{ color: '#666', fontSize: '1rem', marginTop: '15px' }}>
              If you have any questions about these Terms & Conditions, please contact us:
            </p>
            <p style={{ color: '#333', fontSize: '1rem', marginTop: '15px' }}>
              <strong>Email:</strong> support@printercarepros.com
            </p>
            <p style={{ color: '#333', fontSize: '1rem' }}>
              <strong>Phone:</strong> +1 (555) 123-4567
            </p>
            <p style={{ color: '#333', fontSize: '1rem' }}>
              <strong>Address:</strong> 1234 Maplewood Drive, Springfield, IL 62704
            </p>
          </section>
        </article>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
